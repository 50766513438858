<template>
  <v-sheet flat>
    <v-card flat class="ma-1">
      <v-card-text class="px-10">
        <v-form lazy-validation ref="form">
          <v-row dense class="my-2">
            <v-col cols="12">
              <span class="title font-weight-bold">Project Settings</span>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0">
            <v-col cols="12">
              <span class="subtitle-1 mb-0">Project Name *</span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-text-field
                name="project_name"
                type="text"
                autocomplete="off"
                v-model="formProjectName"
                outlined
                dense
                class="subtitle-1"
                data-test="project-name"
                data-cy="fd-project-project-name"
                @input="
                  updateUIState({
                    key: 'project_name',
                    value: formProjectName,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formProjectName,
                    vuexValue: null,
                  })
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mt-5 mx-0">
            <v-col cols="12">
              <span class="subtitle-1">Number of unique DUTs in Fixture *</span>
            </v-col>
          </v-row>
          <v-row dense class="mb-5">
            <v-col cols="12" md="6">
              <v-select
                :items="[1, 2]"
                name="number_of_dtus"
                v-model="formNumberOfDuts"
                outlined
                dense
                class="subtitle-1"
                data-test="number-of-duts"
                @change="
                  updateUIState({
                    key: 'number_of_duts',
                    value: formNumberOfDuts,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formNumberOfDuts,
                    vuexValue: null,
                  })
                "
                data-cy="fd-project-number-of-duts"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0">
            <v-col cols="12">
              <span class="subtitle-1">First DUT Name *</span>
            </v-col>
          </v-row>
          <v-row dense class="ma-0 pa-0">
            <v-col cols="12">
              <span
                >Pick a name to reference this device. We'll get specific
                revision and assembly numbers later.</span
              >
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="6">
              <v-text-field
                name="dut_name_1"
                type="text"
                v-model="formDutName1"
                autocomplete="off"
                outlined
                dense
                class="subtitle-1"
                data-test="dut-name-1"
                data-cy="fd-project-dut-name-1"
                @input="
                  updateUIState({
                    key: 'dut_name_1',
                    value: formDutName1,
                  })
                "
                :rules="
                  hybridRules({
                    required: true,
                    formValue: formDutName1,
                    vuexValue: null,
                  })
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <transition name="slide">
            <div v-show="this.formNumberOfDuts > 1" key="1">
              <v-row dense class="pa-0 ma-0">
                <v-col cols="12">
                  <span class="subtitle-1">Second DUT Name *</span>
                </v-col>
              </v-row>
              <v-row dense class="ma-0 pa-0">
                <v-col cols="12">
                  <span
                    >Pick a name to reference this device. We'll get specific
                    revision and assembly numbers later.</span
                  >
                </v-col>
              </v-row>
              <v-row dense class="my-2">
                <v-col cols="12" md="6">
                  <v-text-field
                    name="second_dut_name"
                    type="text"
                    v-model="formDutName2"
                    autocomplete="off"
                    outlined
                    dense
                    class="subtitle-1"
                    data-test="dut-name-2"
                    @input="
                      updateUIState({
                        key: 'dut_name_2',
                        value: formDutName2,
                      })
                    "
                    :rules="
                      hybridRules({
                        required: formNumberOfDuts > 1,
                        formValue: formDutName2,
                        vuexValue: null,
                      })
                    "
                    data-cy="fd-project-dut-name-2"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </transition>
          <v-row dense class="pa-0 mx-0 mt-7">
            <v-col cols="12" md="6">
              <span class="subtitle-1">Fixture Design Settings</span>
            </v-col>
          </v-row>
          <v-row v-if="editMode" dense class="mt-2">
            <v-col cols="12" md="6">
              <v-alert class="body-2 white--text" color="secondary" dense
                >To select a new fixture type, create a new Project.</v-alert
              >
            </v-col>
          </v-row>
          <v-row dense class="mt-7">
            <v-col cols="12" md="6">
              <BaseItemContainer
                type="Fixture Type"
                :item="fixture_config"
                @clickHandler="ftHandler"
                data-cy="fixture-type-selector"
              />
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mx-0 mt-10">
            <v-col cols="12">
              <span class="title font-weight-bold">Quote Settings</span>
            </v-col>
          </v-row>
          <v-row dense class="pa-0 mx-0 mt-7">
            <v-col cols="12">
              <span class="subtitle-1">Link to Quote</span>
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12">
              <span class="subtitle-1"
                >- If left empty a placeholder Quote and Dut Details models will
                be created</span
              >
            </v-col>
          </v-row>
          <v-row dense class="my-2">
            <v-col cols="12" md="3">
              <CreateRelationModal
                v-if="Object.keys(quote).length == 0"
                toolbarText="Quotes"
                labelText="Project Name"
                buttonText="Select a Quote to link this project to"
                :arrayList="mappedResults"
                @searchCreateRelation="onSearchCreateRelation"
                @onCreateRelation="createRelation"
                @onClearList="clearList"
              />
              <v-btn
                v-else
                class="subtitle-1"
                block
                color="secondary"
                @click="unlinkQuote"
              >
                Unlink Quote to this Project
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="mt-7">
            <v-col cols="12" md="6">
              <v-sheet
                v-if="Object.keys(quote).length > 0"
                outlined
                height="auto"
                class="pa-5"
              >
                <v-card flat>
                  <v-sheet id="scroll-body" class="scroller">
                    <v-row dense>
                      <v-col
                        cols="12"
                        class="d-flex flex-column space-between px-2"
                      >
                        <QuoteDetailsSection
                          :quote="mappedQuote"
                          data-test="quote-setup-div"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="px-2">
                        <FixtureDetailsSection
                          :quote="mappedQuote"
                          data-test="fixture-details-div"
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="dutDetails.length > 0" dense>
                      <v-col
                        cols="12"
                        v-for="(dut, index) in mappedDutDetails"
                        :key="index"
                        class="mb-5"
                      >
                        <DutDetailsCard
                          :dut="dut"
                          :quoteName="quote.project_name"
                          :dutConfigs="
                            dutConfigs.length > index ? dutConfigs[index] : null
                          "
                          data-test="dut-details-card"
                          @downloadFile="onDownloadFile"
                        />
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row dense class="ml-10 my-10">
          <v-col cols="12" md="6">
            <v-row>
              <v-btn x-large color="secondary" @click="onSaveProject" data-cy="fd-project-continue-button">{{
                editMode || Object.keys(project).length > 0
                  ? (!inputValid ? "Save and " : "") + "Continue"
                  : "Start Project"
              }}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @delete="removeDut(selected)"
      @donothing="stopDialog"
    />
    <BaseBottomSelect
      :dialog="dialog"
      :items="items"
      :selected="selected"
      :callback="callback"
      @toggle_ft="toggle_ft"
      @dialogOff="nothing"
    />
  </v-sheet>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ProjectSetupPage",
  mixins: [BottomDialog],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: null,
    },
    dutDetails: {
      type: Array,
      default: null,
    },
    fixtureConfig: {
      type: Object,
      default: null,
    },
    fixturesConfigs: {
      type: Array,
      default: null,
    },
    inputValid: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    quote: {
      type: Object,
      default: null,
    },
    dutConfigs: {
      type: Array,
      default: null,
    },
    projectType: {
      type: Array,
      default: () => [],
    },
    projectTimeline: {
      type: Array,
      default: () => [],
    },
    fixtureWiring: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CreateRelationModal: () =>
      import("@/modules/user-profile/components/CreateRelationModal.vue"),
    QuoteDetailsSection: () => import("../components/QuoteDetailsSection.vue"),
    FixtureDetailsSection: () =>
      import("../components/FixtureDetailsSection.vue"),
    DutDetailsCard: () =>
      import("@/modules/quotes/components/DutDetailsCard.vue"),
  },
  data() {
    return {
      results: null,
      mappedResults: null,
      DEV_FIXTURE_DEFAULT_VOLUME: 500,
      PROD_FIXTURE_DEFAULT_VOLUME: 10000,
      DEFAULT_FIXTURE_CONFIG: {
        pk: 1,
        description: "DEV260 Development Fixture",
      },
      DEFAULT_CONNECTION_TYPE: {
        pk: 4,
        description: "Wire-Wrap",
      },
      DEFAULT_TIP_STYPE: {
        pk: 3,
        description: "Spear",
      },
      DEFAULT_FD_VERSION: 1,
      DEFAULT_PCB_THICKNESS: 1.6,
      items: [],
      dialog: false,
      selected: {},
      callback: "",
      formProjectName: this.editMode ? this.project.project_name : null,
      formNumberOfDuts: this.editMode
        ? Object.keys(this.project.dut_config_details).length
        : 1,
      formDutName1:
        this.editMode && Object.keys(this.project.dut_config_details).length > 0
          ? Object.values(this.project.dut_config_details)[0].name
          : null,
      formDutName2:
        this.editMode && Object.keys(this.project.dut_config_details).length > 1
          ? Object.values(this.project.dut_config_details)[1].name
          : null,
      fixture_config: this.editMode ? this.fixtureConfig : { pk: null },
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
      },
    };
  },
  computed: {
    mappedQuote() {
      const typeFound = this.projectType.find(
        (ptype) => ptype.pk == this.quote.project_type
      );
      const timelineFound = this.projectTimeline.find(
        (ttype) => ttype.pk == this.quote.project_timeline
      );
      const wiringFound = this.fixtureWiring.find(
        (wiring) => wiring.pk == this.quote.fixture_wiring
      );
      return Object.assign(
        { ...this.quote },
        {
          project_type_name: typeFound ? typeFound.description : null,
          project_timeline_name: timelineFound
            ? timelineFound.description
            : null,
          project_wiring_name: wiringFound ? wiringFound.description : null,
        }
      );
    },
    mappedDutDetails() {
      return this.dutDetails.map((dut) => ({
        ...dut,
        design_files: [
          {
            name: "DUT File",
            url: dut.test_point_file,
          },
          {
            name: "STEP File",
            url: dut.step_file,
          },
          {
            name: "Schematic",
            url: dut.schematic_file,
          },
          {
            name: "Gerbers",
            url: dut.gerber_zip,
          },
          {
            name: "ODB++",
            url: dut.odb_zip,
          },
          {
            name: "IPC-2581",
            url: dut.ipc_zip,
          },
          {
            name: "Other Files",
            url: dut.other_file,
          },
        ],
      }));
    },
  },
  methods: {
    ...mapActions({
      saveQuote: "quotes/saveQuote",
      getQuote: "quotes/getQuote",
      saveDutDetails: "quotes/saveDutDetail",
      getDutDetails: "quotes/getDutDetails",
      removeDutDetail: "quotes/deleteDutDetail",
      getQuoteAddress: "quotes/getQuoteAddress",
      postProject: "projects/saveProject",
      removeProject: "projects/deleteProject",
      postFixtureDesign: "fixturedesign/saveFixtureDesign",
      saveDesignFilePackage: "fixturedesign/postDesignFilePackage",
      createDutConfig: "dutconfigs/saveDutConfigs",
      toggleLoading: "ui/loading",
    }),
    ...mapMutations({
      setUser: "projects/SET_USER",
    }),
    hybridRules({ required }) {
      const rules = [];
      if (required) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    ftHandler() {
      if (this.editMode || (!this.isInitialState && !this.isAdmin)) return;
      this.items = this.fixturesConfigs.reduce((filtered, fixture) => {
        if (fixture.public) {
          filtered.push({
            pk: fixture.pk,
            name: fixture.description,
            description: fixture.blurb,
            thumbnail: fixture.thumbnail,
          });
        } else {
          if (this.isAdmin) {
            filtered.push({
              pk: fixture.pk,
              name: fixture.description,
              description: fixture.blurb,
              thumbnail: fixture.thumbnail,
            });
          }
        }
        return filtered;
      }, []);
      this.selected = this.fixture_config;
      this.callback = "toggle_ft";
      this.startDialog();
    },
    toggle_ft(payload) {
      this.fixture_config = payload;
      this.stopDialog();
    },
    startDialog() {
      this.dialog = true;
    },
    stopDialog() {
      this.dialog = false;
    },
    nothing() {
      this.stopDialog();
    },
    async onSaveProject() {
      if (!this.$refs.form.validate()) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "Please fill up all required fields."
        );
        return;
      }
      if (!this.fixture_config.pk) {
        this.$store.commit(
          "ui/SNACK_BAR",
          "The missing Fixture Type is required."
        );
        return;
      }
      if (this.inputValid && Object.keys(this.project).length > 1) {
        this.onNextTab();
        return;
      }
      const formPayload = {
        payload: {
          project_name: this.formProjectName,
          fixture_config: this.fixture_config.pk,
          thumbnail: this.fixture_config.thumbnail || null,
          version: this.DEFAULT_FD_VERSION,
        },
        dut_config: {
          number_of_duts: this.formNumberOfDuts,
          dut_name_1: this.formDutName1,
          dut_name_2: this.formDutName2,
        },
        quote: Object.keys(this.quote).length > 0 ? this.quote.id : null,
      };
      if (this.editMode || Object.keys(this.project).length > 1) {
        this.$emit("updateData", formPayload);
      } else {
        this.$emit("saveData", formPayload);
      }
    },
    initDutName2() {
      this.formDutName2 = null;
    },
    onNextTab() {
      this.$emit("nextTab");
    },
    onPreviousTab() {
      this.$emit("previousTab");
    },
    updateUIState(payload) {
      this.$emit("onUpdateUIState", payload);
    },
    async onSearchCreateRelation(payload) {
      console.log("Search is clicked with string: ", payload);
      try {
        this.results = await this.$store.dispatch("quotes/searchQuotes", {
          params: { search: payload },
        });
        this.mappedResults = this.results.map((element) => {
          return {
            id: element.id,
            name: element.project_name,
          };
        });
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async createRelation({ id }) {
      console.log("Item is selected with id: ", id);
      const record = this.results.find((element) => element.id == id);
      if (record) {
        this.$store.commit("projects/SET_QUOTE", record);
      }
      try {
        if (record.billing_address) {
          await this.getQuoteAddress({
            id: record.billing_address,
            isBilling: true,
          });
        }
        if (record.shipping_address) {
          await this.getQuoteAddress({
            id: record.shipping_address,
            isBilling: false,
          });
        }
        this.$emit("refreshAddresses");
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    clearList() {
      this.results = this.mappedResults = null;
    },
    unlinkQuote() {
      this.$store.commit("projects/SET_QUOTE", {});
    },
    onDownloadFile(payload) {
      this.$emit("onDownloadFile", payload);
    },
  },
  mounted() {
    this.$emit("scrollToTop");
    this.$refs.form.validate();
  },
};
</script>
